<template>
  <mobile-screen
    :header="true"
    screen-class="resources-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "resources",
              "resource-function",
              "linked-resources"
            )
          }}
        </div>
        <template v-slot:right>
          <button @click="saveLinkedResources">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
      <section class="search-wrapper time-zone-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName('resources', 'resource-function', 'search')
            "
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
    </template>
    <ul
      class="clebex-item-section pill"
      v-if="resourceList && resourceList.length"
    >
      <li
        class="clebex-item-section-item"
        v-for="resourceItem in resourceList"
        :key="resourceItem.id"
      >
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div class="checkbox slide">
                <input
                  type="checkbox"
                  :id="`resource${resourceItem.id}`"
                  :name="`resource${resourceItem.id}`"
                  v-model="resourceItem.is_linked"
                  :checked="resourceItem.is_linked"
                />
                <label :for="`resource${resourceItem.id}`"></label>
              </div>
            </dt>
            <span class="inner-text">
              <span>
                <span class="highlight">{{
                  `${resourceItem.resource_name}, `
                }}</span>
                {{ resourceItem.level_name }}
              </span>
            </span>
          </dl>
        </div>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState } from "vuex";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  name: "EditLinkedResources",
  data() {
    return {
      searchQuery: ""
    };
  },
  computed: {
    ...mapState("resource", ["resourceLinked", "resource"]),
    resourceList() {
      const query = this.searchQuery;
      if (this.resourceLinked && this.resourceLinked.length) {
        this.resourceLinked.forEach(resource => {
          resource.is_linked = resource.is_linked ? true : false;
        });
        if (query && query.length > 1) {
          return this.resourceLinked.filter(item =>
            item.resource_name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.resourceLinked;
        }
      }
      return null;
    }
  },
  methods: {
    saveLinkedResources() {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .put(
          `${apiEndpoints.company.resourceLinked}/${this.resource.data.id}`,
          {
            resources: this.resourceLinked
              .filter(resource => resource.is_linked)
              .map(resource => resource.id)
          }
        )
        .then(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
          this.$router.push({
            name: "r_edit-resource-edit-function",
            params: this.$route.params
          });
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
<style lang="scss" scoped>
.clebex-item-dl {
  padding: 4px;
  .inner-text {
    margin-left: 10px;
    font-size: 15px;
    span {
      color: $dusty-gray;
      .highlight {
        color: $bright-gray;
      }
    }
  }
}
</style>
